<template>
    <div id="app">
        <router-view/>
    </div>
</template>

<script>
    import {logoutFromApp} from "@/services/auth";

    export default {
        name: 'App',
        mounted () {
            let customJavaScript = document.createElement('script');
            customJavaScript.async = true;
            customJavaScript.setAttribute('src', this.baseUrl + 'assets/js/custom.js');
            document.head.appendChild(customJavaScript)
        },
        created: function () {
            this.$http.interceptors.response.use(function (response) {
                return response;
            }, function (error) {
                if (error.response.status === 401 && error.response.data.error === "Unauthorised") {
                    logoutFromApp();
                }
                return Promise.reject(error)
            });
        }
    }
</script>

<style>
    ._vue-flash-msg-body {
        z-index: 1099 !important;
    }

    #leftNav a.router-link-exact-active {
        background-color: #20c997;
        color: white;
    }
</style>
