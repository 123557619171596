import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import AuthLayout from "../layouts/AuthLayout"
import DashboardLayout from "../layouts/DashboardLayout"

Vue.use(VueRouter);

const routes = [
    {
        path: "/login",
        component: AuthLayout,
        children: [{
            path: "/login",
            name: "Login",
            component: () => import('../views/Login.vue')
        }, {
          path: '/expired',
          name: 'DomainExpired',
          component: () => import('../views/DomainExpired.vue')
        }],
    }, {
        path: "/",
        component: DashboardLayout,
        meta: {requiresAuth: true},
        children: [
            {
                path: '/',
                name: 'Home',
                component: Home
            }, {
                path: '/member/create',
                name: 'MemberCreate',
                component: () => import('../views/member/MemberCreate.vue')
            }, {
                path: '/member/lists',
                name: 'MemberView',
                component: () => import('../views/member/MemberView.vue')
            }, {
                path: '/admin/lists',
                name: 'AdminView',
                component: () => import('../views/admin/AdminView.vue')
            }, {
                path: '/admin/profile',
                name: 'AdminProfile',
                component: () => import('../views/admin/AdminProfile.vue')
            }, {
                path: '/admin/tasks',
                name: 'AdminTasks',
                component: () => import('../views/admin/AdminTasks.vue')
            }, {
                path: '/admin/permission',
                name: 'AdminPermission',
                component: () => import('../views/admin/AdminPermission.vue')
            }, {
                path: '/share/collection',
                name: 'ShareCollection',
                component: () => import('../views/share/ShareCollection.vue')
            }, {
                path: '/share/withdraw',
                name: 'ShareWithdraw',
                component: () => import('../views/share/ShareWithdraw.vue')
            }, {
                path: '/share/lists',
                name: 'ShareView',
                component: () => import('../views/share/ShareView.vue')
            }, {
                path: '/loan/create',
                name: 'LoanCreate',
                component: () => import('../views/loan/LoanCreate.vue')
            }, {
                path: '/installment/create',
                name: 'InstallmentCreate',
                component: () => import('../views/loan/InstallmentCreate.vue')
            }, {
                path: '/loan/lists',
                name: 'LoanView',
                component: () => import('../views/loan/LoanView.vue')
            }, {
                path: '/fdr/collection',
                name: 'FdrCreate',
                component: () => import('../views/fdr/FdrCreate.vue')
            }, {
                path: '/fdr/withdraw',
                name: 'FdrWithdraw',
                component: () => import('../views/fdr/FdrWithdraw.vue')
            }, {
                path: '/fdr/lists',
                name: 'FdrView',
                component: () => import('../views/fdr/FdrView.vue')
            }, {
                path: '/saving/collection',
                name: 'SavingCreate',
                component: () => import('../views/saving/SavingCreate.vue')
            }, {
                path: '/saving/withdraw',
                name: 'SavingWithdraw',
                component: () => import('../views/saving/SavingWithdraw.vue')
            }, {
                path: '/saving/lists',
                name: 'SavingView',
                component: () => import('../views/saving/SavingView.vue')
            }, {
                path: '/borrow/collection',
                name: 'BorrowCreate',
                component: () => import('../views/borrow/BorrowCreate.vue')
            }, {
                path: '/borrow/withdraw',
                name: 'BorrowWithdraw',
                component: () => import('../views/borrow/BorrowWithdraw.vue')
            }, {
                path: '/borrow/lists',
                name: 'BorrowView',
                component: () => import('../views/borrow/BorrowView.vue')
            }, {
                path: '/salary/create',
                name: 'SalaryCreate',
                component: () => import('../views/salary/SalaryCreate.vue')
            }, {
                path: '/salary/lists',
                name: 'SalaryView',
                component: () => import('../views/salary/SalaryView.vue')
            }, {
                path: '/particular-transaction/create',
                name: 'ParticularTransaction',
                component: () => import('../views/particular/ParticularTransaction.vue')
            }, {
                path: '/particular-transaction/lists',
                name: 'ParticularTransactionView',
                component: () => import('../views/particular/ParticularTransactionView.vue')
            }, {
                path: '/collection/saving',
                name: 'SavingCollection',
                component: () => import('../views/collection/SavingCollection.vue')
            }, {
                path: '/collection/installment',
                name: 'InstallmentCollection',
                component: () => import('../views/collection/InstallmentCollection.vue')
            }, {
              path: '/collection/general',
              name: 'CommonCollection',
              component: () => import('../views/collection/CommonCollection.vue')
            }, {
              path: '/collection/due-installment',
              name: 'DueInstallment',
              component: () => import('../views/collection/DueInstallment.vue')
            }, {
                path: '/product/lists',
                name: 'Products',
                component: () => import('../views/products/Products.vue')
            }, {
                path: '/product/brand',
                name: 'Brand',
                component: () => import('../views/products/Brand.vue')
            }, {
                path: '/product/categories',
                name: 'Category',
                component: () => import('../views/products/Category.vue')
            }, {
                path: '/product/suppliers',
                name: 'Supplier',
                component: () => import('../views/products/Supplier.vue')
            }, {
                path: '/product/purchase',
                name: 'Purchase',
                component: () => import('../views/products/Purchase.vue')
            }, {
                path: '/product/sales',
                name: 'Sales',
                component: () => import('../views/products/Sales.vue')
            }, {
                path: '/product/search',
                name: 'ProductReports',
                component: () => import('../views/products/Reports.vue')
            }, {
                path: '/product/stock',
                name: 'ProductStock',
                component: () => import('../views/products/ProductStock.vue')
            }, {
                path: '/product/loan',
                name: 'ProductLoan',
                component: () => import('../views/products/ProductLoan.vue')
            }, {
                path: '/product/installment/collection',
                name: 'ProductInstallCollect',
                component: () => import('../views/products/ProductInstallCollect.vue')
            }, {
                path: '/product/customer',
                name: 'ProductCustomer',
                component: () => import('../views/products/ProductCustomer.vue')
            }, {
                path: '/report/collection',
                name: 'MonthlyCollection',
                component: () => import('../views/reports/MonthlyCollection.vue')
            }, {
                path: '/report/expenditure',
                name: 'MonthlyExpenditure',
                component: () => import('../views/reports/MonthlyExpenditure.vue')
            }, {
                path: '/report/collection-expenditure',
                name: 'CollectionExpenditure',
                component: () => import('../views/reports/CollectionExpenditure.vue')
            }, {
                path: '/report/update-collection-expenditure',
                name: 'UpdateCollectionExpenditure',
                component: () => import('../views/reports/UpdateCollectionExpenditure.vue')
            }, {
                path: '/report/transaction',
                name: 'TransactionReport',
                component: () => import('../views/reports/TransactionReport.vue')
            }, {
                path: '/report/tasks',
                name: 'TasksReport',
                component: () => import('../views/reports/TasksReport.vue')
            }, {
                path: '/accounts/general-ledger',
                name: 'GeneralLedger',
                component: () => import('../views/accounts/GeneralLedger.vue')
            }, {
                path: '/accounts/trial-balance',
                name: 'TrialBalance',
                component: () => import('../views/accounts/TrialBalance.vue')
            }, {
                path: '/accounts/profit-loss',
                name: 'ProfitLoss',
                component: () => import('../views/accounts/ProfitLoss.vue')
            }, {
                path: '/accounts/balance-sheet',
                name: 'BalanceSheet',
                component: () => import('../views/accounts/BalanceSheet.vue')
            }, {
                path: '/accounts/detail-lists',
                name: 'DetailLists',
                component: () => import('../views/accounts/DetailLists.vue')
            }, {
                path: '/sms/new',
                name: 'SmsCreate',
                component: () => import('../views/sms/SmsCreate.vue')
            }, {
                path: '/sms/lists',
                name: 'SmsView',
                component: () => import('../views/sms/SmsView.vue')
            }, {
                path: '/particular/lists',
                name: 'ParticularView',
                component: () => import('../views/particular/ParticularView.vue')
            }, {
                path: '/branch/lists',
                name: 'BranchView',
                component: () => import('../views/branch/BranchView.vue')
            }, {
                path: '/group-centers',
                name: 'GroupCenters',
                component: () => import('../views/group/GroupCenters.vue')
            }, {
                path: '/category/lists',
                name: 'CategoryView',
                component: () => import('../views/category/CategoryView.vue')
            }, {
                path: '/committee/lists',
                name: 'TeamView',
                component: () => import('../views/team/TeamView.vue')
            }, {
                path: '/settings/view',
                name: 'Settings',
                component: () => import('../views/Settings.vue')
            }, {
                path: '*',
                name: '404',
                component: () => import('../views/404.vue')
            }
        ],
    },
];

const router = new VueRouter({
    hashbang: false,
    mode: 'history',
    base: process.env.BASE_URL,
    routes: routes
});

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (window.$cookies.isKey("token")) {
            let userData = JSON.parse(localStorage.getItem("users"));
            let accessRole = userData.role;
            let accessRoutes = userData.accessRoutes;
            const routeRequired = accessRoutes.includes(to.path);
            if (routeRequired || accessRole == 1) {
                next();
                return
            }
            alert('দুঃখিত কাজটি করার অনুমতি নেই আপনার');
            next('/');
            return
        }
        next('/login')
    } else {
        next()
    }
});

export default router
