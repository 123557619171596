<template>
    <div class="home">
        <div class="row">

            <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                <div class="text-center bg-white">
                    <img class="img-fluid" v-bind:src="`${IMAGEURL+'setting/'+configData.lg_logo}`" alt="Large Logo">
                    <h4 class="pt-1">{{configData.society_name}}</h4>
                    <h6>{{configData.society_theme}}</h6>
                </div>
            </div>


            <div class="col-12 col-sm-12 col-md-12 col-lg-12" v-if="accessModules.includes('2') || accessRole == 1">
                <div class="row">

                    <div class="col-12 col-sm-6 col-md-3 col-lg-3">
                        <div class="card bgs-primary">
                            <div class="card-body">
                                <div class="media align-items-center">
                            <span class="p-3 mr-3 feature-icon rounded">
                                <i class="fa fa-calendar float-right" aria-hidden="true"></i>
                            </span>
                                    <div class="media-body text-center">
                                        <p class="fs-18 text-white mb-2">{{todayName}}</p>
                                        <span class="fs-48 text-white font-w600">{{todayDate | enToBn}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 col-sm-6 col-md-3 col-lg-3">
                        <div class="card bgs-info">
                            <div class="card-body">
                                <div class="media align-items-center">
                            <span class="p-3 mr-3 feature-icon rounded">
                                <i class="fa fa-money-bill float-right" aria-hidden="true"></i>
                            </span>
                                    <div class="media-body text-center">
                                        <p class="fs-18 text-white mb-2">বর্তমান হস্তমজুদ </p>
                                        <span class="fs-48 text-white font-w600">{{parseData.handCash | transactionConvertToBangla}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 col-sm-6 col-md-3 col-lg-3">
                        <div class="card bgs-success">
                            <div class="card-body">
                                <div class="media align-items-center">
                            <span class="p-3 mr-3 feature-icon rounded">
                                <i class="fa fa-user-circle float-right" aria-hidden="true"></i>
                            </span>
                                    <div class="media-body text-center">
                                        <p class="fs-18 text-white mb-2">সর্বমোট সদস্য - {{parseData.memberTotal | translateToBangla}}</p>
                                        <span class="fs-48 text-white font-w600">পুরুষ-{{parseData.memberMale | translateToBangla}} নারী-{{parseData.memberFemale | translateToBangla}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 col-sm-6 col-md-3 col-lg-3">
                        <div class="card bgs-secondary">
                            <div class="card-body">
                                <div class="media align-items-center">
                            <span class="p-3 mr-3 feature-icon rounded">
                                <i class="fa fa-user-plus float-right" aria-hidden="true"></i>
                            </span>
                                    <div class="media-body text-center">
                                        <p class="fs-18 text-white mb-2">সর্বমোট এডমিন </p>
                                        <span class="fs-48 text-white font-w600">{{parseData.adminTotal | translateToBangla}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 col-md-6">
                        <canvas id="bar" width="500" height="300"></canvas>
                    </div>

                    <div class="col-12 col-md-6">
                        <canvas id="planet-chart" width="500" height="300"></canvas>
                    </div>

                </div>
            </div>

            <div class="col-12 col-md-12">
                <div v-if="loader" class="bg-light text-center p-2">
                    <div class="spinner-border" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
    import Chart from 'chart.js';

    export default {
        name: 'Home',
        components: {},
        data() {
            return {
                loader: false,
                configData: JSON.parse(localStorage.getItem("users")),
                accessRole: JSON.parse(localStorage.getItem("users")).role,
                accessModules: JSON.parse(localStorage.getItem("users")).accessModulesId,
                todayName: '',
                todayDate: '',
                monthlyCollection: null,
                monthlyExpenditure: null,
                monthsArray: null,
                parseData: {
                    handCash: 0,
                    memberTotal: 0,
                    adminTotal: 0,
                    shareTotal: 0,
                    loanTotal: 0,
                    fdrTotal: 0,
                    savingTotal: 0,
                    borrowTotal: 0
                }
            }
        },
        mounted() {
            this.getDashboardData();
        },
        created() {
            document.title = "ড্যাশবোর্ড ";
            this.updateTime();
        },
        methods: {
            updateTime() {
                let week = ['রবিবার', 'সোমবার', 'মঙ্গলবার', 'বুধবার', 'বৃহস্পতিবার', 'শুক্রবার', 'শনিবার'];
                let cd = new Date();
                this.todayName = week[cd.getDay()];
                this.todayDate = this.zeroPadding(cd.getDate(), 2) + '-' + this.zeroPadding(cd.getMonth() + 1, 2) + '-' + this.zeroPadding(cd.getFullYear(), 4);
            },
            zeroPadding(num, digit) {
                let zero = '';
                for (let i = 0; i < digit; i++) {
                    zero += '0';
                }
                return (zero + num).slice(-digit);
            },
            createPieChart() {
                const ctx = document.getElementById('bar');
                new Chart(ctx, {
                    type: 'pie',
                    data: {
                        datasets: [{
                            data: [this.parseData.shareTotal, this.parseData.loanTotal, this.parseData.fdrTotal, this.parseData.borrowTotal],
                            backgroundColor: ["#33cc33", "#ff5050", "#9900cc", "#ffff00"],
                            label: 'Pie chart'
                        }],
                        labels: ["উদ্যোক্তা শেয়ার", "ঋণ বিনিয়োগ", "প্রজেক্ট শেয়ার", "কর্জ হিসাব"]
                    },
                    options: {
                        responsive: true
                    }
                });
            },
            createLineChart() {
                const ctx = document.getElementById('planet-chart');
                const MONTHS = this.monthsArray;
                const line1 = this.monthlyExpenditure;
                const line2 = this.monthlyCollection;

                new Chart(ctx, {
                    type: 'line',
                    data: {
                        labels: MONTHS,
                        datasets: [{
                            label: "মাসিক জমা ",
                            backgroundColor: 'rgb(54, 162, 235)',
                            borderColor: 'rgb(153, 102, 255)',
                            data: line2,
                            fill: false
                        }, {
                            label: "মাসিক খরচ ",
                            backgroundColor: 'rgb(255, 99, 132)',
                            borderColor: 'rgb(255, 159, 64)',
                            data: line1,
                            fill: false
                        }]
                    },
                    options: {
                        title: {
                            display: true,
                            text: 'জমা খরচের অবস্থা'
                        },
                        tooltips: {
                            mode: 'index',
                            intersect: false,
                        },
                        hover: {
                            mode: 'nearest',
                            intersect: true
                        },
                        scales: {
                            xAxes: [{
                                display: true,
                                scaleLabel: {
                                    display: true,
                                    labelString: new Date().getFullYear()
                                }
                            }],
                            yAxes: [{
                                display: true,
                                scaleLabel: {
                                    display: true,
                                },
                            }]
                        },
                        responsive: true
                    }
                });
            },
            getDashboardData() {
                this.loader = true;
                this.$http.get('reports/dashboard').then((response) => {
                    this.monthlyCollection = response.data.monthlyCollection;
                    this.monthlyExpenditure = response.data.monthlyExpenditure;
                    this.monthsArray = response.data.monthsArray;
                    this.parseData = response.data.parseData;
                    this.createPieChart();
                    this.createLineChart();
                    this.loader = false;
                }).catch((e) => {
                    this.monthlyCollection = null;
                    this.monthlyExpenditure = null;
                    this.monthsArray = null;
                    this.parseData = null;
                    this.flashMessage.error({message: e});
                    this.loader = false;
                })
            }
        }
    }
</script>

<style>
    .card {
        box-shadow: 0 0 0 1px #e2e5e8;
        margin-bottom: 30px;
        transition: all 0.5s ease-in-out;
        border: 0px solid transparent;
        border-radius: 1.25rem;
    }

    .card:hover {
        box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    }

    .feature-icon {
        border: 1px solid #DCD7E8;
        border-radius: 1.25rem !important;
        font-size: 40px;
        color: white;
    }

    .fs-18 {
        font-size: 18px !important;
        line-height: 1.5;
    }

    .font-w600 {
        font-weight: 600;
    }

    .fs-48 {
        font-size: 20px !important;
        line-height: 1.25;
    }

    .bgs-primary {
        background: linear-gradient(-45deg, #ff1939, #40189D) !important;
    }

    .bgs-info {
        background: linear-gradient(-45deg, #3498db, #1b4f72) !important;
    }

    .bgs-success {
        background: linear-gradient(-45deg, #1BD084, #45b39d) !important;
    }

    .bgs-secondary {
        background: linear-gradient(-45deg, #8e44ad, #34495e) !important;
    }
</style>
